import type { GatsbyBrowser } from 'gatsby';

export const onPreRouteUpdate: GatsbyBrowser['onPreRouteUpdate'] = ({
  location,
  prevLocation,
}) => {
  const body_element = document.getElementsByTagName('body');

  // web-view
  const webViewVal = /is-web-view=true/;
  const setWebView = () => body_element[0].classList.add('is-web-view');

  // display body tag
  const displayBody = () => body_element[0].classList.add('displayable');

  if (document.cookie.match(webViewVal) || location.search.match(webViewVal)) {
    setWebView();
  }
  displayBody();
};
